<template>
  <a-badge :status="status" :text="text"></a-badge>
</template>

<script>
export default {
  name: 'ScanStateTag',
  props: {
    state: { type: Number, default: 0 }
  },
  data () {
    return { status: 'default', text: '' }
  },
  mounted () {
    switch (this.state) {
      case 2:
        this.status = 'success'
        this.text = '已扫描'
        break
      case 1:
        this.color = 'processing'
        this.text = '扫描中'
        break
      default:
        this.color = 'default'
        this.text = '未扫描'
        break
    }
  }
}
</script>

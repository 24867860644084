<template>
  <a-card :bordered="false">
    <a-form-model :label-col="{ span: 4, style: { textAlign: 'left' } }" :model="form" :wrapper-col="{ span: 18 }">
      <a-row>
        <a-col :md="8" :sm="24">
          <a-form-model-item label="URL" prop="url">
            <a-input v-model="form.url" :allow-clear="true" :max-length="128" @pressEnter="() => { this.pagination.current = 1; this.fetch() }"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="() => { this.pagination.current = 1; this.fetch() }">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-button icon="plus" type="primary" @click="$refs.createForm.show()" style="margin-bottom: 24px;">创建</a-button>
    <create-form ref="createForm" @ok="fetch"></create-form>

    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="(p, f, s) => { this.pagination.current = 1; this.sorter = s; this.fetch() }"
    >
      <template slot="url" slot-scope="text, record">
        <a-tag :color="record.verified ? 'blue' : ''">{{ record.verified ? '已认证' : '未认证' }}</a-tag>
        <span>{{ record.url }}</span>
      </template>
      <template slot="state" slot-scope="state">
        <scan-state-tag :state="state"></scan-state-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <template v-if="record.verified">
          <a-button size="small" type="link" @click="$router.push({ name: 'SiteDetail', params: { id: record.id } })">查看</a-button>
        </template>
        <template v-else>
          <a-button size="small" type="link" style="color: #faad14" @click="$refs.verifyModal.show()">认证</a-button>
          <verify-modal ref="verifyModal" :site-id="record.id" @ok="fetch"></verify-modal>
        </template>
        <a-divider type="vertical"></a-divider>
        <a-popconfirm title="是否确定删除？" @confirm="handleDelete(record.id)">
          <a-button size="small" type="link" style="color: #f5222d">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-pagination
      v-if="pagination.total > 0"
      v-model="pagination.current"
      :pageSize="pagination.pageSize"
      :show-size-changer="true"
      :show-total="total => `共 ${total} 条`"
      :total="pagination.total"
      @change="page => { this.pagination.page = page; this.fetch() }"
      @showSizeChange="(current) => { this.pagination.current = current; this.fetch() }"
      style="margin-top: 24px; float: right;"
    ></a-pagination>
  </a-card>
</template>

<script>
import { deleteSite, getSites } from '@/api/site'
import CreateForm from './modules/CreateForm'
import ScanStateTag from './modules/ScanStateTag'
import VerifyModal from './modules/VerifyModal'

export default {
  name: 'SiteList',
  components: { CreateForm, ScanStateTag, VerifyModal },
  data () {
    return {
      form: {},
      visible: false,
      columns: [
        { dataIndex: 'url', title: 'URL', width: 480, scopedSlots: { customRender: 'url' } },
        { dataIndex: 'updated_at', title: '更新时间', width: 200, sorter: true },
        { dataIndex: 'site_risk_count', title: '风险统计', width: 240, align: 'center' },
        { dataIndex: 'state', title: '扫描状态', width: 240, scopedSlots: { customRender: 'state' } },
        { title: '操作', width: 160, fixed: 'right', align: 'center', scopedSlots: { customRender: 'operation' } }
      ],
      dataSource: [],
      loading: false,
      pagination: { current: 1, pageSize: 20, total: 0 },
      sorter: {}
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      const params = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      }
      if (this.sorter.field && this.sorter.order) {
        params.sort_by = this.sorter.field
        params.order_by = this.sorter.order
      }
      if (this.form.url) params.url = this.form.url
      getSites(params).then(res => {
        this.dataSource = res.data
        this.pagination.total = res.total
      }).finally(() => { this.loading = false })
    },
    handleDelete (id) {
      deleteSite(id).then(res => {
        this.$message.success(res.message)
        this.fetch()
      })
    }
  }
}
</script>

<template>
  <a-modal
    :confirm-loading="loading"
    ok-text="验证"
    title="站点验证"
    :visible="visible"
    :width="720"
    @ok="handleOk"
    @cancel="() => { this.visible = false }"
  >
    <a-descriptions :column="1">
      <a-descriptions-item>1、请点击 <a @click="handleClick">下载</a> 获取验证文件（_verify_file.txt）；</a-descriptions-item>
      <a-descriptions-item>2、请将验证文件放置于站点域名的根目录下（http://www.example.com/_verify_file.txt）；</a-descriptions-item>
      <a-descriptions-item>3、请点击 <strong>验证</strong> 按钮进行验证；</a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script>
import fileDownload from 'js-file-download'

import { verifySite } from '@/api/site'

export default {
  name: 'VerifyModal',
  props: {
    siteId: { type: String, required: true }
  },
  data () {
    return {
      loading: false,
      visible: false
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    handleOk () {
      this.loading = true
      verifySite(this.siteId).then(res => {
        this.$message.success(res.message)
        this.$emit('ok')
        this.visible = false
      }).finally(() => { this.loading = false })
    },
    handleClick () {
      fileDownload(this.siteId.replaceAll('-', ''), '_verify_file.txt')
    }
  }
}
</script>

<template>
  <a-modal
    :confirm-loading="loading"
    title="创建站点"
    :visible="visible"
    @ok="handleOk"
    @cancel="() => { this.$refs.form.resetFields(); this.visible = false }"
  >
    <a-form-model ref="form" :label-col="{ span: 4, style: { textAlign: 'left' } }" :model="form" :rules="rules" :wrapper-col="{ span: 20 }">
      <a-form-model-item label="URL" prop="url">
        <a-input v-model="form.url" :max-length="128" placeholder="以 http:// 或 https:// 开头"></a-input>
      </a-form-model-item>
      <a-form-model-item label="解析 IP" prop="ip_address">
        <a-input v-model="form.ip_address">
          <a-tooltip slot="suffix" title="如果有 CDN，请填写解析 IP 以便获取准确信息">
            <a-icon type="info-circle" style="color: rgba(0,0,0,.45)"></a-icon>
          </a-tooltip>
        </a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createSite } from '@/api/site'

export default {
  name: 'CreateForm',
  data () {
    return {
      loading: false,
      visible: false,
      form: {},
      rules: {
        url: [
          { message: '请输入 URL', required: true },
          { message: '请输入正确的 URL', type: 'url', trigger: 'blur' }
        ],
        ip_address: [{ message: '请输入正确的 IP 地址', pattern: /^(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}$/, trigger: 'blur' }]
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          createSite(this.form).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.visible = false
            this.$refs.form.resetFields()
          }).finally(() => { this.loading = false })
        }
      })
    }
  }
}
</script>
